import classNames from 'classnames';
import { JSX } from 'react';

type UnconfirmedChangesIndicatorProps = {
  className?: string;
  size?: 'sm' | 'md';
};

function UnconfirmedChangesIndicator({
  className = null,
  size = 'md',
}: UnconfirmedChangesIndicatorProps): JSX.Element {
  const sizeClassName = size === 'sm' ? 'h-[11px] w-[11px]' : 'h-[13px] w-[13px]';
  const positionClassName = size === 'sm' ? '-right-[3px] -top-[3px]' : 'right-[-5px] top-[-5px]';

  return (
    <div className={classNames('-translate-1/2 absolute', positionClassName, className)}>
      <div
        className={`rounded-full border-2 border-solid border-white bg-crusta ${sizeClassName}`}
      />
    </div>
  );
}

export default UnconfirmedChangesIndicator;
